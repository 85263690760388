import { Component, Prop } from 'vue-property-decorator'
import jsPDF from 'jspdf'
import { GForm } from '@/components/forms/GForm'
import axios from 'axios'

const baseURl = process.env.VUE_APP_BASE_URL

@Component
export class GPdf extends GForm {
  @Prop({ type: [Object, Array, Number, String] }) readonly item!: any;
  @Prop({ type: Object, required: false }) readonly options!: any;
  @Prop({ type: Boolean, required: false, default: false }) readonly disabled!: boolean;

  preview = false
  docData: Record<string, any> = {}

  formatDate (date: string) {
    if (!date) return
    const split = date.split('/')
    const day = split[0]
    let month = split[1]
    const year = split[2]

    switch (month) {
      case 'Ene': month = '01'; break
      case 'Feb': month = '02'; break
      case 'Mar': month = '03'; break
      case 'Abr': month = '04'; break
      case 'May': month = '05'; break
      case 'Jun': month = '06'; break
      case 'Jul': month = '07'; break
      case 'Ago': month = '08'; break
      case 'Sep': month = '09'; break
      case 'Oct': month = '10'; break
      case 'Nov': month = '11'; break
      case 'Dic': month = '12'; break
      default: month = '01'
    }
    return `${year}/${month}/${day}`
  }

  async getSystem () {
    const system = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })

    this.$set(this.docData, 'company', {
      photo: await this.loadImage(system.photo),
      phone: system.phoneWork,
      name: system.companyName,
    })
  }

  replaceVariables (text: string, variables: Record<string, any>) {
    const varRegex = /\$\w+\$/
    const matches = text.match(varRegex)
    for (const match of matches || []) {
      const matchText = match
      const varName = matchText?.replaceAll('$', '')
      text = text.replace(matchText, variables?.[varName])
    }
    return text
  }

  sumArray (array) {
    let sum = 0
    array?.forEach(item => sum += item || 0)
    return sum
  }

  capitalizeFirstLetter (val: string) {
    val = val.toLowerCase()
    return String(val).charAt(0).toUpperCase() + String(val).slice(1)
  }

  setFont (doc) {
    this.setDefaultFont(doc)
  }

  setDefaultFont (doc: jsPDF) {
    doc.setFont(undefined, 'normal')
    doc.setFontSize(12)
    doc.setTextColor(115, 115, 115)
  }

  addDate (doc: jsPDF, x: number, y: number, date): number {
    // Agregar texto de fecha primero
    doc.setTextColor(115, 115, 115)
    doc.setFontSize(12)
    doc.text(date, x, y)
    return y
  }

  separator (doc: jsPDF, y: number) {
    doc.setLineWidth(0.3)
    doc.setDrawColor(245, 245, 245)
    doc.line(10, y, doc.internal.pageSize.getWidth() - 10, y)
    return y + 10
  }

  addDotedTitle (doc: jsPDF, x, y, text) {
    doc.setFont(undefined, 'bold')
    doc.text(`\u2022 ${text}`, x, y)
    this.setDefaultFont(doc)
    return y + 7
  }

  footer (doc: jsPDF) {
    doc.setTextColor(97, 133, 219)
    doc.setFontSize(12)
    const height = doc.internal.pageSize.getHeight() - 5
    doc.text('Powered by', this.getCenterWidth(doc, 'Powered by') - 8, height)
    doc.addImage(require('@/assets/genio/Logo.jpg'), 'JPEG', this.getCenterWidth(doc, 'Powered by') + 15.5, height - 4.5, 15, 5.5)
    this.setDefaultFont(doc)
  }

  addClosing (doc, left: string[], right: string[]) {
    let y = doc.internal.pageSize.getHeight() - 25
    left.forEach((item, index) => {
      doc.setFontSize(10)
      if (index === 0) {
        doc.setFont(undefined, 'bold')
      }
      doc.text(item, 15, y)
      this.setDefaultFont(doc)
      y += 5
    })
    y = doc.internal.pageSize.getHeight() - 20
    right.forEach((item, index) => {
      doc.setFontSize(10)
      if (index === 0) {
        doc.setFont(undefined, 'bold')
      }
      doc.text(item, this.getAlignEnd(doc, item), y)
      this.setDefaultFont(doc)
      y += 5
    })
  }

  addSectionTitle (doc: jsPDF, x, y, text) {
    if (y > doc.internal.pageSize.getHeight() - 30) {
      doc.addPage('a4', 'p')
      y = 15
      this.footer(doc)
    }
    doc.setFont(undefined, 'bold')
    doc.setFontSize(16)
    doc.text(text, x, y)
    this.setDefaultFont(doc)
    return y + 7
  }

  newPage (doc: jsPDF, y) {
    doc.addPage('a4', 'p')
    this.footer(doc)
    return 15
  }

  insertNumberedList (doc: jsPDF, x, y, list: string[]) {
    list.forEach((item, index) => {
      y = this.addParagraph(doc, x, y, `${index + 1}. ${item}`)
    })
    return y
  }

  insertList (doc: jsPDF, x, y, list: string[], values: string[]) {
    list.forEach((item, index) => {
      const stringModifiers = {
        noBullet: false,
        bold: false,
      }
      if (item.split('|').length > 1) {
        const modifiers = item.split('|')[1].split('/')
        stringModifiers.noBullet = modifiers.includes('no-bullet')

        item = item.split('|')[0]
      }
      const words = item.split(' ')
      let newLine = stringModifiers.noBullet ? '' : '\u2022 '
      let testNewLine = newLine + words[0] + ' '
      words.forEach((word, index) => {
        if (doc.getTextWidth(testNewLine) < doc.internal.pageSize.getWidth() - 40) {
          newLine += word + ' '
          testNewLine += words[index + 1]
        } else {
          doc.text(newLine, x, y)
          y += 7
          newLine = word + ' '
          testNewLine = words[index + 1] + ' '
        }
      })
      doc.text(String(newLine || ''), x, y)
      if (values[index]) {
        if (values[index].split('|').length > 1) {
          const modifiers = values[index].split('|')[1].split('/')
          stringModifiers.bold = modifiers.includes('bold')

          values[index] = values[index].split('|')[0]
        }
        if (stringModifiers.bold) {
          doc.setFont(undefined, 'bold')
        }
        doc.text(String(values[index] || ''), this.getAlignEnd(doc, values[index] || ''), y)
        this.setDefaultFont(doc)
      }
      if (y >= doc.internal.pageSize.getHeight() - 40) {
        doc.addPage()
        this.footer(doc)
        y = 15
      } else {
        y += 7
      }
    })
    return y
  }

  getCenterWidth (doc: jsPDF, text): number {
    return (doc.internal.pageSize.getWidth() - doc.getTextWidth(text)) / 2
  }

  getAlignEnd (doc: jsPDF, text): number {
    return doc.internal.pageSize.getWidth() - doc.getTextWidth(text) - 10
  }

  addBoldText (doc: jsPDF, x: number, y: number, text: string): number {
    doc.setFont(undefined, 'bold')
    doc.text(text, x, y)
    this.setDefaultFont(doc)
    y += 7
    return y
  }

  addTitle (doc: jsPDF, y: number, title: string): number {
    doc.setFontSize(24)
    doc.setFont(undefined, 'bold')
    doc.setTextColor(0, 51, 165)
    doc.text(title, (doc.internal.pageSize.getWidth() - doc.getTextWidth(title)) / 2, y)
    this.setDefaultFont(doc)
    return y + 7
  }

  addImage (doc: jsPDF, imageData: string, x: number, y: number): number {
    // Alinear la imagen a la derecha con el tamaño especificado
    const imgWidth = 80 // Ancho de la imagen
    const imgHeight = 8 // Alto de la imagen
    const pageWidth = doc.internal.pageSize.getWidth()
    const xPosition = pageWidth - imgWidth - 10 // 10 es el margen derecho

    // Agregar imagen al PDF
    doc.addImage(imageData, 'PNG', xPosition, y, imgWidth, imgHeight)
    return y + imgHeight
  }

  addParagraph (doc, x, y, paragraph, maxWidth = 180, justify = true) {
    const words = paragraph.split(' ').filter(word => word.length > 0)
    let line = ''
    const lineWidth = 0

    words.forEach((word, index) => {
      const testLine = line + word + ' '
      const testLineWidth = doc.getTextWidth(testLine)

      if (testLineWidth > maxWidth && line !== '') {
        if (justify) {
          // Justificar la línea
          const lineWords = line.trim().split(' ').map(w => ({ text: w, style: 'normal' }))
          const lineWidth = doc.getTextWidth(line.trim())
          this.justifyLine(doc, lineWords, lineWidth, maxWidth, x, y)
        } else {
          // Imprimir la línea sin justificar
          doc.setFont('helvetica', 'normal')
          doc.text(line.trim(), x, y)
        }
        y += 7
        line = word + ' '
      } else {
        line = testLine
      }

      // Si es la última palabra, imprimir la línea restante
      if (index === words.length - 1 && line.trim() !== '') {
        if (justify) {
          const lineWords = line.trim().split(' ').map(w => ({ text: w, style: 'normal' }))
          const lineWidth = doc.getTextWidth(line.trim())
          this.justifyLine(doc, lineWords, lineWidth, maxWidth, x, y)
        } else {
          doc.setFont('helvetica', 'normal')
          doc.text(line.trim(), x, y)
        }
        y += 7
      }
    })

    return y
  }

  applyTextWithStyles (doc, x, y, text, maxWidth = 175) {
    const boldRegex = /\|b\|(.*?)\|\/b\|/g
    let lastIndex = 0
    const context = { currentLine: [], lineWidth: 0 }
    let result

    while ((result = boldRegex.exec(text)) !== null) {
      // Handle normal text before the |b| tag
      y = this.handleNormalText(doc, x, y, text.slice(lastIndex, result.index), maxWidth, context)
      // Handle bold text inside |b|...|/b|
      y = this.handleBoldText(doc, x, y, result[1], maxWidth, context)
      lastIndex = result.index + result[0].length // Update the index
    }

    // Process any remaining normal text after the last |b|...|/b|
    y = this.handleNormalText(doc, x, y, text.slice(lastIndex), maxWidth, context)

    // Justify any remaining text in the current line
    if (context.currentLine.length > 0) {
      this.justifyLine(doc, context.currentLine, context.lineWidth, maxWidth, x, y, context)
      y += 7 // Move to the next line after justifying
    }

    return y
  }

  // Function to handle normal text
  handleNormalText (doc, x, y, normalText, maxWidth, context) {
    const fontStyle = 'normal'
    const words = normalText.split(' ').filter(word => word.length > 0) // Filter out empty words

    words.forEach(word => {
      const wordWidth = doc.getTextWidth(word + ' ')

      if (context.lineWidth + wordWidth > maxWidth) {
        // Justify the current line before exceeding the limit
        this.justifyLine(doc, context.currentLine, context.lineWidth, maxWidth, x, y, context)
        y += 7 // Move to the next line
        context.currentLine = [{ text: word, style: fontStyle }] // Start a new line
        context.lineWidth = wordWidth
      } else {
        context.currentLine.push({ text: word, style: fontStyle })
        context.lineWidth += wordWidth
      }
    })

    return y
  }

  // Function to handle bold text
  handleBoldText (doc, x, y, boldText, maxWidth, context) {
    const fontStyle = 'bold'
    const words = boldText.split(' ').filter(word => word.length > 0) // Filter out empty words

    words.forEach(words => {
      const wordWidth = doc.getTextWidth(words + ' ')

      if (context.lineWidth + wordWidth > maxWidth) {
        // Justify the current line before exceeding the limit
        this.justifyLine(doc, context.currentLine, context.lineWidth, maxWidth, x, y, context)
        y += 7 // Move to the next line
        context.currentLine = [{ text: words, style: fontStyle }] // Start a new line with bold text
        context.lineWidth = wordWidth
      } else {
        context.currentLine.push({ text: words, style: fontStyle })
        context.lineWidth += wordWidth
      }
    })

    return y
  }

  // Updated justifyLine function to accept context
  justifyLine (doc, wordsWithStyles, lineWidth, maxWidth, x, y, context = null) {
    if (wordsWithStyles.length === 0) return // No hay nada que justificar

    // Calcular el ancho total de las palabras con sus estilos
    let totalWordsWidth = 0
    wordsWithStyles.forEach(wordObj => {
      doc.setFont('helvetica', wordObj.style || 'normal')
      totalWordsWidth += doc.getTextWidth(wordObj.text)
    })

    const totalSpace = maxWidth - totalWordsWidth
    const numberOfSpaces = wordsWithStyles.length - 1
    const spaceWidth = numberOfSpaces > 0 ? totalSpace / numberOfSpaces : 0
    let currentX = x

    wordsWithStyles.forEach((wordObj, index) => {
      if (!wordObj.text || wordObj.text.length === 0) return // Omitir palabras vacías

      // Establecer el estilo de fuente adecuado
      doc.setFont('helvetica', wordObj.style || 'normal')

      // Imprimir la palabra en la posición actual
      doc.text(wordObj.text, currentX, y)

      // Calcular el ancho de la palabra
      const wordWidth = doc.getTextWidth(wordObj.text)

      // Incrementar la posición X para la siguiente palabra
      currentX += wordWidth

      // Agregar el espacio adicional entre palabras, excepto después de la última palabra
      if (index < wordsWithStyles.length - 1) {
        currentX += spaceWidth
      }
    })

    // Reiniciar el contexto después de justificar la línea, si se proporciona el contexto
    if (context) {
      context.currentLine = []
      context.lineWidth = 0
    }
  }

  addSeparator (doc: jsPDF, x: number, y: number): number {
    const pageWidth = doc.internal.pageSize.getWidth()
    doc.setLineWidth(0.3)
    doc.setDrawColor(140) // Color gris claro
    doc.line(x, y, pageWidth - x, y)
    return y + 5 // Retorna la nueva coordenada y después de la línea
  }

  addTextCenter (doc: jsPDF, parts: {
    text: string
    style: string
  }[], x: number, y: number, newLineAfterPart = false, addMargin = 0, addNextLine = false, addFinalMargin = 5): number {
    const pageWidth = doc.internal.pageSize.getWidth() - 15
    doc.setFontSize(12)

    parts.forEach(part => {
      doc.setFont('helvetica', part.style)
      const lines = this.splitText(doc, part.text, part.style, x, y, pageWidth)
      lines.forEach((line, index) => {
        // Aplicar el margen solo en la primera línea del párrafo
        const currentX = index === 0 ? line.x + addMargin : line.x
        doc.text(line.text, currentX, line.y, { align: 'center' })
        x = line.x + doc.getTextWidth(line.text)
        y = line.y
        if (x > pageWidth) {
          x = 10
          y += 5
        }
        if (addNextLine) {
          x = 10
          y += 5
        }
      })
      if (newLineAfterPart) {
        x = 10
        y += 10
      }
    })

    return y + addFinalMargin
  }

  addTextParts (doc, parts, x, y, newLineAfterPart = false, addMargin = 0, addNextLine = false, addFinalMargin = 5) {
    const pageWidth = doc.internal.pageSize.getWidth() - 20 // Considera márgenes

    parts.forEach(part => {
      doc.setFont('helvetica', part.style)
      const lines = this.splitText(doc, part.text, part.style, x, y, pageWidth)

      lines.forEach((line, lineIndex) => {
        const { words, y: lineY } = line
        const isLastLine = lineIndex === lines.length - 1

        if (words.length === 0) return

        if (!isLastLine && words.length > 1) {
          // Justificar línea
          const totalWordsWidth = words.reduce((total, word) => total + doc.getTextWidth(word), 0)
          const spaceWidth = (pageWidth - totalWordsWidth - x) / (words.length - 1)

          let currentX = x + addMargin
          words.forEach((word, index) => {
            doc.text(word, currentX, lineY)
            const wordWidth = doc.getTextWidth(word)
            currentX += wordWidth + spaceWidth
          })
        } else {
          // Línea normal (no justificar)
          const lineText = words.join(' ')
          doc.text(lineText, x + addMargin, lineY)
        }

        y = lineY

        if (addNextLine) {
          x = 10
          y += 5
        }
      })

      if (newLineAfterPart) {
        x = 10
        y += 10
      }
    })

    return y + addFinalMargin
  }

  splitText (doc, text, style, x, y, maxWidth) {
    const words = text.split(' ')
    const lines = []
    let currentLineWords = []
    let currentLineWidth = 0

    doc.setFont('helvetica', style)

    words.forEach((word, index) => {
      const wordWidth = doc.getTextWidth(word + ' ')
      if (currentLineWidth + wordWidth > maxWidth - x && currentLineWords.length > 0) {
        lines.push({ words: currentLineWords, y })
        currentLineWords = [word]
        currentLineWidth = wordWidth
        y += 7 // Ajusta el espaciado entre líneas según sea necesario
      } else {
        currentLineWords.push(word)
        currentLineWidth += wordWidth
      }

      // Si es la última palabra, agregar la línea actual
      if (index === words.length - 1) {
        lines.push({ words: currentLineWords, y })
      }
    })

    return lines
  }

  async getImageData (url: string): Promise<string> {
    const response = await fetch(url)

    const blob = await response.blob()
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }

  async loadImage (url: string) {
    if (!url || typeof url !== 'string' || !/^https?:\/\//.test(url)) {
      console.error('Invalid URL provided:', url)
      return null
    }

    let mimeType = 'image/jpeg' // Valor predeterminado

    if (url.endsWith('.png')) {
      mimeType = 'image/png'
    } else if (url.endsWith('.jpeg') || url.endsWith('.jpg')) {
      mimeType = 'image/jpeg'
    }
    const encodedUrl = encodeURIComponent(url)
    const uri = `${baseURl}/file-proxy/get-image?url=${encodedUrl}`
    try {
      const response = await axios.get(uri, {
        responseType: 'arraybuffer',
      })
      const base64 = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      )
      return `data:${mimeType};base64,${base64}`
    } catch (error) {
      console.error('Error loading car photo:', error)
      return null
    }
  }
}
